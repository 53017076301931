import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { ref, watch, computed } from '@vue/composition-api'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import store from '@/store'

export default function useInstancesList(root) {
  // Use toast
  const toast = useToast()

  const refInstancesList = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: 'Name', sortable: true },
    { key: 'domain', label: 'Domain' },
    { key: 'type', label: 'Selected Type' },
    { key: 'actions' },
  ]
  const defaultPerPage = Number(localStorage.getItem('instancesPerPage'))
  const perPage = ref(defaultPerPage || 10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('created_at')
  const isSortDirDesc = ref(true)
  let debounceTimeout = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refInstancesList.value ? refInstancesList.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refetchData = () => {
    refInstancesList.value.refresh()
  }
  watch([currentPage, perPage, searchQuery],
    ([currentPageVal, perPageVal, searchQueryVal],
      [currentPagePrevVal, perPagePrevVal, searchQueryPrevVal]) => {
      if (currentPageVal !== currentPagePrevVal) {
        pushStateFiltersParams(root, 'admin-instances-list', 'currentPage', currentPageVal)
      }
      if (perPageVal !== perPagePrevVal) {
        pushStateFiltersParams(root, 'admin-instances-list', 'perPage', perPageVal)
      }
      if (searchQueryVal !== searchQueryPrevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(root, 'admin-instances-list', 'searchQuery', searchQueryVal)
        }, 250)
      }
    })

  const fetchInstancesList = (ctx, callback) => {
    const payloadData = {
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
    }
    if (searchQuery.value) payloadData.name = searchQuery.value

    store
      .dispatch('app-instances/fetchInstancesList', payloadData)
      .then(response => {
        const { data } = response.data
        const { total } = response.data.meta

        callback(data)
        totalItems.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching Instances' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteInstance = ID => axios
    .delete(`auth/schools/${ID}`)
    .then(() => {
      refetchData()
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success deleting Instance',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error deleting Instance',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    totalItems,
    isSortDirDesc,
    refInstancesList,
    fetchInstancesList,
    deleteInstance,

    refetchData,
  }
}
