<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <div class="table-header m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mr-1 mb-0">Show</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
              :value="perPage"
              @input="updatePerPage"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <label class="mr-1 mb-0">Search</label>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                class="w-25"
              />

              <b-button
                class="ml-1"
                variant="primary"
                :to="{ name: 'admin-create-instance' }"
              >
                <span class="mr-25 align-middle">Create Instance</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refInstancesList"
        :items="fetchInstancesList"
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :sort-by.sync="sortBy"
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <!-- Loader -->
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>

        <!-- Column: name -->
        <template #cell(name)="data">
          <b-link
            :to="{ name: 'admin-edit-instance', params: { id: data.item.id } }"
          >{{ data.item.name }}</b-link>
        </template>

        <!-- Column: domain -->
        <template #cell(domain)="data">
          <b-link>{{ data.item.config.domain_name }}</b-link>
        </template>

        <!-- Column: type -->
        <template #cell(type)="data">
          <span>{{ getInstanceType(data.item.config.type) }}</span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="mx-auto p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              :to="{ name: 'admin-edit-instance', params: { id: data.item.id } }"
            >
              <span>Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              :disabled="!data.item.is_deletable"
              @click="showDeleteConfirmationModal(data.item.id)"
            >
              <span>Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>

    <confirmation-modal
      :toggle-modal="confirmationModalShow"
      :title="'Are you sure'"
      :is-loading="isLoading"
      :message="'Are you sure you want to delete this instance? All the related data will be lost!'"
      @close-modal="confirmationModalShow = !confirmationModalShow"
      @confirm="confirmDeleting"
    />
  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BLink,
  BPagination, BDropdown, BDropdownItem, BButton, BSpinner,
} from 'bootstrap-vue'

import vSelect from 'vue-select'

import { onUnmounted } from '@vue/composition-api'
import store from '@/store'

import useInstancesList from '@/views/admin/instances/instances-list/useInstancesList'
import storeModule from '@/views/admin/instances/instancesStoreModule'
import ConfirmationModal from '@/views/components/confirmation/ConfirmationModal.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BLink,
    BPagination,
    BDropdown,
    BDropdownItem,
    BButton,
    BSpinner,

    vSelect,
    ConfirmationModal,
  },
  data() {
    return {
      instanceIdForDeleting: '',
      confirmationModalShow: false,
      isLoading: false,
    }
  },
  setup(props, { root }) {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-instances'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      totalItems,
      isSortDirDesc,
      refInstancesList,
      fetchInstancesList,
      deleteInstance,

      refetchData,
    } = useInstancesList(root)

    return {
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      totalItems,
      isSortDirDesc,
      refInstancesList,
      fetchInstancesList,
      deleteInstance,

      refetchData,
    }
  },
  watch: {
    $route() {
      if (!this.$route.query.currentPage) {
        this.setFilterParams()
      }
      this.refetchData()
    },
  },
  created() {
    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
  },
  methods: {
    updatePerPage(val) {
      localStorage.setItem('instancesPerPage', val)
      this.perPage = val
    },
    setFilterParams() {
      const query = { ...this.$route.query }
      const defaultPerPage = Number(localStorage.getItem('instancesPerPage'))
      this.currentPage = Number(query.currentPage) || 1
      this.perPage = Number(query.perPage) || defaultPerPage || 10
      this.searchQuery = query.searchQuery || ''
    },
    getInstanceType(type) {
      return type === 1 ? 'School' : 'Camp'
    },
    showDeleteConfirmationModal(instanceId) {
      this.instanceIdForDeleting = instanceId
      this.confirmationModalShow = true
    },
    async confirmDeleting() {
      this.isLoading = true
      await this.deleteInstance(this.instanceIdForDeleting)
      this.isLoading = false
      this.confirmationModalShow = false
    },
  },
}
</script>

<style lang="scss" scoped>
  .table-header {

    label {
      margin-bottom: 0;
    }
  }
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
